<template>
  <div class="dashboard">
    <vuestic-widget>
      <div class="row">
        <div class="col-md-6 borderBox">
          <br>
          <h2>Hola
            <strong>{{nameme}}</strong> Estas en el evento:
          </h2>
          <h2>{{event.name}}</h2>
          <h3>
            <strong>Código:</strong>
            {{event.code}}
          </h3>

          <h4>Navega en el menu para configurar el evento</h4>
        </div>
      
  <div class="col-md-6 borderBox" v-if="rol===1 || rol===2 || rol===3">
          <br>
       <h3>Usa este QR para entrar al evento desde la aplicación</h3>
          <!--<input type="text" v-model="qrcode">-->
        <div class="row">
           <div class="col-md-3">
           </div>
          <div class="col-md-6">

         <!--  <qrcode value="qrcode" :options="{ width: 200 }"></qrcode>-->
         <vue-qr
        :text=qrcode
        :correctLevel=3
        :dotScale=1.0
        :size=250
    ></vue-qr>
              </div>
           <div class="col-md-3">
           </div>
        </div>

        </div>
      </div>
        <div class="col-md-6 borderBox" v-if="rol===1 || rol===2">
          <br>
          <h3>Estatus del Evento</h3>
          <h2 v-if="event.status === 1">Abierto</h2>
          <h2 v-if="event.status === 2">Lanzado</h2>
          <h2 v-if="event.status === 3">Cerrado</h2>

          <h3>Cambiar Estatus</h3>
          <div class="row">
            <div class="col-md-7">
              <multiselect v-model="statusEvent" :options="this.optionsStatus" label="name"></multiselect>
            </div>
            <div class="col-md-5">
              <button class="btn btn-primary btn-micro" v-on:click="updateStatusForm">Actualizar</button>
            </div>
          </div>
        </div>


    </vuestic-widget>
  </div>
</template>

<script>
import DashboardInfoWidgets from "./DashboardInfoWidgets";
import UsersMembersTab from "./users-and-members-tab/UsersMembersTab.vue";
import SetupProfileTab from "./setup-profile-tab/SetupProfileTab.vue";
import FeaturesTab from "./features-tab/FeaturesTab.vue";
import DataVisualisationTab from "./data-visualisation-tab/DataVisualisation.vue";
import DashboardBottomWidgets from "./DashboardBottomWidgets.vue";
import Multiselect from "vue-multiselect";
import { mapState, mapActions } from "vuex";
import Cookies from "js-cookie";
import Vue from 'vue'
//import VueQRCodeComponent from 'vue-qrcode-component'

// Register the Vue component
//Vue.component('qr-code', VueQRCodeComponent)
//import VueQrcode from '@chenfengyuan/vue-qrcode';

//Vue.component(VueQrcode.name, VueQrcode);
import VueQr from 'vue-qr'

Vue.component('vue-qr', VueQr)
export default {
  name: "dashboard",
  components: {
    DataVisualisationTab,
    DashboardInfoWidgets,
    UsersMembersTab,
    SetupProfileTab,
    FeaturesTab,
    DashboardBottomWidgets,
    Multiselect,
    //VueQrcode
  },
  computed: {
    ...mapState({
      nameme: state => state.auth.me.name,
      rol: state => state.auth.me.rol.id,
      event: state => state.event.event,
      statusEvent_set: state => state.event.event.status
    }),
    statusEventFunction() {
      if (this.statusEvent_set === 1) {
        this.statusEvent = { id: this.statusEvent_set, name: "Abierto" };
      }
      if (this.statusEvent_set === 2) {
        this.statusEvent = { id: this.statusEvent_set, name: "Lanzado" };
      }
      if (this.statusEvent_set === 3) {
        this.statusEvent = { id: this.statusEvent_set, name: "Cerrado" };
      }
    }
  },
  created() {
    this.statusEventFunction;


     this.loadEvent({id: this.event.id})
                            .then((data) => {
                              console.log(data);
                               this.qrcode = this.encrypt(data.data.password,data.data.code);
                            })
                            .catch((data) => {
                                this.error = data.message
                            })

  },

  data() {
    return {
      statusEvent: null,
      qrcode:'',
      optionsStatus: [
        { id: 1, name: "Abierto" },
        { id: 2, name: "Lanzado" },
        { id: 3, name: "Cerrado" }
      ]
    };
  },
  methods: {
    ...mapActions(["updateStatusEvent","loadEvent"]),
    encrypt(password,code)
    {
        var one = code.substring(0,1);
        var two = code.substring(1,2);
        var three = code.substring(2,3);
        var four = code.substring(3,4);
        var five = code.substring(4,5);
        var six = code.substring(5,6);

        var inverse_password = this.inverse(password);

        var qrcode = 'FbN7QTVUBd'+six+five+four+three+two+one+'CRonZepLdf'+inverse_password+'khtQFHT3zE';
        return qrcode;

    },
    inverse(key) {
        var x = key.length;
        var keyinverse = "";

        while (x>=0) {
          keyinverse = keyinverse + key.charAt(x);
          x--;
        }
        return keyinverse;
      },

    updateStatusForm() {
      this.$validator.validate().then(result => {
        if (result) {
          let form = {
            id: this.event.id,
            status: this.statusEvent.id
          };
          this.updateStatusEvent(form)
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: "success"
              });
              this.$store.state.order.status;
              this.event.status = data.status;
            })
            .catch(data => {
              this.error = data.message;
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.borderBox {
  border: 1px solid rgb(175, 175, 175);
  padding: 10px;
}
</style>
